import { connect } from 'react-redux';

// == Import : local
import Dashboard from '../components/dashboard';
// Action Creators
import { loginAction } from '../store/reducer/appReducer';
import { setModalData } from '../store/reducer/modalReducer';
/* === State (données) ===
 * - mapStateToProps retroune un objet de props pour le composant de présentation
 * - mapStateToProps met à dispo 2 params
 *  - state : le state du store (getState)
 *  - ownProps : les props passées au container
 * Pas de data à transmettre ? const mapStateToProps = null;
 */
const mapStateToProps = (state) => ({ 
  firststate: state.appReducer.firststate,
  isConnected: state.appReducer.isConnected
});

/* === Actions ===
 * - mapDispatchToProps retroune un objet de props pour le composant de présentation
 * - mapDispatchToProps met à dispo 2 params
 *  - dispatch : la fonction du store pour dispatcher une action
 *  - ownProps : les props passées au container
 * Pas de disptach à transmettre ? const mapDispatchToProps = {};
 */
const mapDispatchToProps = (dispatch) => ({
  loginAction: (data) => {
    dispatch(loginAction(data))
  },
  setModalData: (data) => {
    dispatch(setModalData(data))
  },
});

// Container
const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

// == Export
export default DashboardContainer;

/* = export à la volée
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Example);
*/