import './header.scss';
import { useNavigate } from "react-router-dom"
import ModalShowContainer from '../../container/modalContainer'
let Header = ({isConnected,logoutAction,setModalData}) => {
    let navigate = useNavigate();
    return (
        <>
        {isConnected &&
            <>
                <ModalShowContainer  />
                <div className="header bg-primary">
                <div className="header__menu">
                    <div className='d-flex justify-content-around p-3'>
                        <i onClick={()=>navigate('/dashboard')} className="fas fa-home menuCustomClass"></i>
                        <i onClick={()=>setModalData({showState:true,title:'Feature',content:'This feature is still on developement'})} className="fas fa-sliders-h menuCustomClass"></i>
                        <i  onClick={()=>logoutAction()} className="fa-solid fa-arrow-right-from-bracket menuCustomClass"></i>
                    </div>
                </div>
                </div>
            </>
        }
        </>
    );
}


export default Header;