// == Initial State
const initialState = {
    title:'test',
    content:'test',
    showState:false
  };
  
  // == Types
    export const SET_MODAL_DATA = 'SET_MODAL_DATA';   // set modal data
  // == Reducer
  export const modalReducer = (state = initialState, action = {}) => {
    
    switch (action.type) {
      
      case SET_MODAL_DATA:
        return  {
        ...state,
        title:action.data.title,
        content:action.data.content,
        showState:action.data.showState
        };
      default:
        return state;
    }
    
  };
  
  // == Action Creators
  export const setModalData = (data) => (
    {
    type: SET_MODAL_DATA,
    data
  });
  
  


  
  
  // == Export
