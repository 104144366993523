// == Initial State
const initialState = {
    firststate:'',
    env:'prod',
    token:'',
    isConnected:false,
    mercureUrlProd:`${window.location.protocol}//${window.location.hostname}/.well-known/mercure`,
    mercureUrlLocal:`${window.location.protocol}//localhost:4500/.well-known/mercure`,
    baseUrlProd :`${window.location.protocol}//${window.location.hostname}/api`,
    baseUrlLocal :`${window.location.protocol}//localhost:9002`,
  };
  
  // == Types
  export const FIRST_TYPE = 'FIRST_TYPE';
  export const LOGIN = 'LOGIN';
  export const LOGOUT = 'LOGOUT';
  

  // == Reducer
  export const appReducer = (state = initialState, action = {}) => {
    
    switch (action.type) {
      case FIRST_TYPE:
        return  {
          ...state,
          firststate:action.data
        };
      case LOGIN:
        sessionStorage.setItem('token', action.data.token);
        return {
          ...state,
          token:action.data.token,
          isConnected:true
        };
      case LOGOUT:
        sessionStorage.removeItem('token');
        return {
          ...state,
          token:'',
          isConnected:false
        };
      default:
        return state;
    }
    
  };
  
  // == Action Creators
  export const firstAction = (data) => (
    {
    type: FIRST_TYPE,
    data
  });
  export const loginAction = (data) => (
    {
    type: LOGIN,
    data
  });
  export const logoutAction = () => (
    {
    type: LOGOUT
    } 
  );
  
  


  
  
  // == Export
  export const baseUrlToUse = initialState.env=="dev"?initialState.baseUrlLocal:initialState.baseUrlProd;
  export const mercureUrlToUse = initialState.env=="dev"?initialState.mercureUrlLocal:initialState.mercureUrlProd;
  export const kafkaUrlToUse = initialState.env=="dev"?"http://localhost:8082/api/v1/kafka/send":"https://drawapp.kpognon.eu/spring/api/v1/kafka/send";
  export const websocketUrlToUse = initialState.env=="dev"?"http://localhost:8082/websocket":"https://drawapp.kpognon.eu/spring/websocket";