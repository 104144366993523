let LegalMention =()=> {
  return (
    <>
        <div>
            <h1>Legal Mention</h1>
            <p> This application is not a official product of a company. It is a personal project developed by a student of the <a href="https://www.2itechacademy.com/">2itech academy </a>
            </p>
            list of rules:
            <ul>
                <li>
                    <p>
                        <strong>
                            You are not allowed to use this application for commercial purposes.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            You are not allowed to use this application for illegal purposes.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            The data you provide will be used only for the purpose of the application.
                        </strong>
                    </p>
                </li>
            </ul>
        </div>
    </>
  )
}

export default LegalMention;