
import { useState,useEffect } from "react"
import { useNavigate,useParams } from "react-router-dom"
import './homepage.scss'
import axios from "axios";
import ModalShowContainer from "../../container/modalContainer";
const qs = require('qs');

let Homepage =({baseUrlToUse,isConnected,setModalData,loginAction})=>{
    let navigate = useNavigate();
    let { recovertoken,confirmationtoken } = useParams();
    useEffect(()=>{
        if(recovertoken!==undefined){
            handleChange('recoverConfirm')
        }
        if(confirmationtoken!==undefined){
            axios.get(`${baseUrlToUse}/confirmation/${confirmationtoken}`,{
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiZWFyZXIiOnRydWV9.3sm0ob4WKULvl_51C_40uaeLhU00aa-CWZgRSUX0KcY`
                }
            }).then((response)=>{
                setModalData({showState:true,title:'Account Info',content:response.data.message})
                if(response.data.status=="201"){
                    clearForms();
                    handleChangeMode('none')
                }
            })
        }
    },[])

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const initialState = {
        setup:'login',
        form:{
            login:{
                login:'',
                password:''
            },
            register:{
                login:'',
                email:'',
                password:''
            },
            recover:{
                email:'', 
                
            },
            recoverConfirm:{
                password:'',
                token:urlParams.get('token')
            }
            
            
        },
        modal:{
            title:'',
            content:'',
            showState:false
        }
        
    }
    let [state,setState ]= useState(initialState)
    let handleChange =(event)=>{
        const { name, value } =event.target;
        state.form[state.setup] = {...state.form[state.setup],[name]:value}
        setState({...state,...state.form[state.setup]})
    }
    let handleChangeMode =(mode)=>{
        
        let setup = mode;
        console.log(setup)
        setState({...state,setup:setup})
    }
    let handleSubmitRecover =(event)=>{
        event.preventDefault();
        console.log(state)
        let data = qs.stringify({...state.form[state.setup]})
        
        axios.post(`${baseUrlToUse}/recover`,data,{
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiZWFyZXIiOnRydWV9.3sm0ob4WKULvl_51C_40uaeLhU00aa-CWZgRSUX0KcY`
            }
        }).then((response)=>{
            console.log(response)
            let newModal = {modal:{showState:true,title:'Connection',content:response.data.message}}
            setModalData({showState:true,title:'Connection',content:response.data.message})
            if(response.data.status=="200"){
                clearForms();
                handleChangeMode('login')
            }
        })
    }
    let handleSubmitRegister =(event)=>{
        event.preventDefault();
        let data = qs.stringify({...state.form[state.setup]})
        
        axios.post(`${baseUrlToUse}/user/register`,data,{
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiZWFyZXIiOnRydWV9.3sm0ob4WKULvl_51C_40uaeLhU00aa-CWZgRSUX0KcY`
            }
        }).then((response)=>{
            console.log(response)
            let newModal = {modal:{showState:true,title:'Connection',content:response.data.message}}
            setModalData({showState:true,title:'Connection',content:response.data.message})
            if(response.data.status=="201"){
                clearForms();
                handleChangeMode('login')
            }
        })
    }
    let handleSubmitLogin =(event)=>{
        event.preventDefault();
        let data = qs.stringify({...state.form[state.setup]})
        
        axios.post(`${baseUrlToUse}/login`,data,{
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiZWFyZXIiOnRydWV9.3sm0ob4WKULvl_51C_40uaeLhU00aa-CWZgRSUX0KcY`
            }
        }).then((response)=>{
            if(response.data.status!=="200"){
                let newModal = {modal:{showState:true,title:'Connection',content:response.data.message}}
                setModalData({showState:true,title:'Connection',content:response.data.message})

            }else{
                loginAction({token:response.data.token})
                navigate('/dashboard')
            }
            
        })
    }
    let handleSubmitRecoverConfirm =(event)=>{
        event.preventDefault();
        let data = qs.stringify({...state.form[state.setup]})
        
        axios.post(`${baseUrlToUse}/recover/confirm`,data,{
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiZWFyZXIiOnRydWV9.3sm0ob4WKULvl_51C_40uaeLhU00aa-CWZgRSUX0KcY`
            }
        }).then((response)=>{
            console.log(response)
            let newModal = {modal:{showState:true,title:'Connection',content:response.data.message}}
            setModalData({showState:true,title:'Connection',content:response.data.message})
            if(response.data.status=="200"){
                clearForms();
                handleChangeMode('none')
            }
        })
    }
    let handleSubmit =(event)=>{
        event.preventDefault();
        switch (state.setup) {
            case 'register':
                handleSubmitRegister(event)
                break;
            case 'login':
                handleSubmitLogin(event)
                break;
            case 'recover':
                handleSubmitRecover(event)
                break;
            case 'recoverConfirm':
                handleSubmitRecoverConfirm(event)
                break;
            default:
                break;
        }
    }
    let clearModal =()=>{
        setState({...state,modal:{showState:false,title:'',content:''}})
    }
    let clearForms =()=>{
        setState({...state,form:{login:{login:'',password:''},register:{login:'',email:'',password:''},recover:{login:'',email:''},recoverConfirm:{password:'',token:''}}})
    }
    return(
        <>
             <ModalShowContainer title={state.modal.title} content={state.modal.content} show={state.modal.showState} />
            <div className="hompageContainer">
                {state.setup=='login' && 
                <>
                    <div className="formContainer">
                        <form className="loginForm" onSubmit={handleSubmit}>
                            <div className="form-group inputPosition">
                                <label className="LabelText" htmlFor="id">Login</label>
                                <input type="text" className="form-control" onChange={handleChange} id="login" name="login" value={state.form.login.login} placeholder="Enter username"/>
                            </div>
                            <div className="form-group inputPosition" >
                                <label className=" LabelText">Password</label>
                                <input type="password" className="form-control"  onChange={handleChange} value={state.form.login.password} id="password" name="password" placeholder="Password"/>
                            </div>
                            <div className="form-group">
                                <div className="nav-link active LabelText2" onClick={()=>handleChangeMode('recover')} >Forgotten password?</div>
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-success inputPosition">Signin</button>
                                <button type="button"  onClick={()=>handleChangeMode('register')} className="btn btn-primary inputPosition">Signup</button>
                            </div>
                        
                        </form>
                    </div>
                </>
                }
                {state.setup=='register' && 
                <>
                    <div className="formContainer">
                        <form className="loginForm" onSubmit={handleSubmit}>
                            <div className="form-group inputPosition">
                                <label className=" LabelText" htmlFor="id">Login</label>
                                <input type="text" className="form-control" onChange={handleChange} id="login" name="login" value={state.form.register.login} placeholder="Username"/>
                            </div>
                            <div className="form-group inputPosition" >
                                <label className=" LabelText">Email</label>
                                <input type="email" className="form-control"  onChange={handleChange} value={state.form.register.email} id="email" name="email" placeholder="Email"/>
                            </div>
                            <div className="form-group inputPosition" >
                                <label className=" LabelText">Password</label>
                                <input type="password" className="form-control"  onChange={handleChange} value={state.form.register.password} id="password" name="password" placeholder="Password"/>
                            </div>
                            <div className="form-group">
                                <div className="nav-link active LabelText2" onClick={()=>handleChangeMode('login')}> Having an account ? </div>
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-success inputPosition">Signup</button>
                            </div>
                        
                        </form>
                    </div>
                </>
                }
                {state.setup=='recover' && 
                <>
                    <div className="formContainer">
                        <form className="loginForm" onSubmit={handleSubmit}>
                            <div className="form-group inputPosition">
                                <label className=" LabelText" htmlFor="id">Email</label>
                                <input type="email" className="form-control" onChange={handleChange} id="login" name="email" value={state.form.recover.email} placeholder="Enter email"/>
                            </div>
                            <div className="form-group">
                                <div className="nav-link active LabelText2"  onClick={()=>handleChangeMode('register')} >Not having an accounnt ?</div>
                            </div>
                            <div className="d-flex">
                            <button type="submit" className="btn btn-success inputPosition">recover</button>
                            </div>
                        
                        </form>
                    </div>
                </>
                }
                {state.setup=='recoverConfirm' && 
                <>
                    <div className="formContainer">
                        <form className="loginForm" onSubmit={handleSubmit}>
                            <div className="form-group inputPosition">
                                <label className=" LabelText" htmlFor="id">New password</label>
                                <input type="text" className="form-control" onChange={handleChange} id="password" name="password" value={state.form.recoverConfirm.password} placeholder="Enter email"/>
                                <input type="hidden" value={state.form.recoverConfirm.token} />
                            </div>
                            <div className="form-group">
                                <div className="nav-link active LabelText2"  onClick={()=>handleChangeMode('register')} >Not having an accounnt ?</div>
                            </div>
                            <div className="d-flex">
                            <button type="submit" className="btn btn-success inputPosition">Change password</button>
                            </div>
                        
                        </form>
                    </div>
                </>
                }
            </div>
        </>
    )
}

export default Homepage;