// https://redux.js.org/api/combinereducers

import { combineReducers } from 'redux';
import {appReducer} from '../store/reducer/appReducer';
import {drawGameReducer} from '../store/reducer/drawGameReducer';
import {modalReducer} from '../store/reducer/modalReducer';
const reducers = {
  appReducer,
  drawGameReducer,
  modalReducer
};

export default combineReducers(reducers);