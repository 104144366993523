import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './modal.scss';
let ModalShow =({title,content,showState,setModalData})=> {
   
   
  const handleClose = () => setModalData({title:'',content:'',showState:false});
//   const handleShow = () => setModalData({title:title,content:content,showState:true});

  return (
    <>

      <Modal show={showState} onHide={handleClose} className="modalCustom">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalShow;