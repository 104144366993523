import { connect } from 'react-redux';

// == Import : local
import Homepage from '../components/homepage';
// Action Creators
import { baseUrlToUse,loginAction,logoutAction } from '../store/reducer/appReducer';
import { setModalData } from '../store/reducer/modalReducer';
/* === State (données) ===
 * - mapStateToProps retroune un objet de props pour le composant de présentation
 * - mapStateToProps met à dispo 2 params
 *  - state : le state du store (getState)
 *  - ownProps : les props passées au container
 * Pas de data à transmettre ? const mapStateToProps = null;
 */
const mapStateToProps = (state) => ({ 
    isConnected: state.appReducer.isConnected,
    baseUrlToUse:baseUrlToUse
});

/* === Actions ===
 * - mapDispatchToProps retroune un objet de props pour le composant de présentation
 * - mapDispatchToProps met à dispo 2 params
 *  - dispatch : la fonction du store pour dispatcher une action
 *  - ownProps : les props passées au container
 * Pas de disptach à transmettre ? const mapDispatchToProps = {};
 */
const mapDispatchToProps = (dispatch) => ({
  setModalData: (data) => {
    dispatch(setModalData(data))
  },
  loginAction: (data) => {
    dispatch(loginAction(data))
  },
  logOutAction: () => {
    dispatch(logoutAction())
  }
});

// Container
const HomepageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Homepage);

// == Export
export default HomepageContainer;

/* = export à la volée
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Example);
*/