import { useEffect, useState } from "react";
import { EventSourcePolyfill } from 'event-source-polyfill';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import './drawGame.scss'  
import axios from "axios";
const qs = require('qs');
export const DrawGame =({drawStatus,switchDrawStatus,switchDrawOff,baseUrlToUse,mercureUrlToUse})=>{
    const [hubData,setHubData]=useState({})
    const [color,setColor]=useState('#000000')
    const [oldPosition,setOldPosition]=useState(null)
    let jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdfX0.uwrelcr6iarVnfHPoU5Yc3xqSg6AfEKb8O_eAuSH4zE"
    useEffect(()=>{
        
        if(hubData['imageUrl']!==undefined){
            var target =  document.getElementById('DrawIt').getContext('2d');
            let {width,height} = document.getElementById('DrawIt').getBoundingClientRect()
            console.log(hubData['imageUrl'])
            var img = new Image(width,height);
            img.src = hubData['imageUrl'];
            img.onload = ()=>{
                target.drawImage(img,0,0);  
            }
        }
            
    },[drawStatus,hubData])
    useEffect(()=>{
        checkCurrentDrawImg()
        // The subscriber subscribes to updates for the https://example.com/users/dunglas topic
        // and to any topic matching https://example.com/books/{id}
        const url = new URL(mercureUrlToUse);
        url.searchParams.append('topic', 'http://example.com/books/1');
        // The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas

        const eventSource = new EventSourcePolyfill(url,{headers: {
        Authorization: `Bearer ${jwt}`
        }});
        // The callback will be called every time an update is published
        eventSource.onmessage = e => {
            let data = JSON.parse(e.data)
            
            if(data['action']=='clear'){
                clearCanvasLocal()
            }
            if(data['imageUrl']!==undefined){
                setHubData(JSON.parse(e.data));
            }
        }
    },[])
    const handleMove=(event)=>{    
        
        var target =  event.target.getContext('2d');
        var rect =  event.target.getBoundingClientRect();
        var x=(event.changedTouches[0].clientX - rect.left) / (rect.right - rect.left) * event.changedTouches[0].target.width;
        var y = (event.changedTouches[0].clientY - rect.top) / (rect.bottom - rect.top) * event.changedTouches[0].target.height;
        let position = { x : x , y:y }
        
        
        if(oldPosition!==null){
            var target =  document.getElementById('DrawIt').getContext('2d');
            target.beginPath(); // begin
            target.strokeStyle = color;
            target.lineWidth = 5;
            target.lineJoin = 'round';
            target.lineCap = 'round';
            target.moveTo(oldPosition.x,oldPosition.y);
            target.lineTo(x,y);
            target.moveTo(x,y);
            target.lineTo(x,y);
            target.stroke();
            target.closePath();
            setOldPosition({x:x,y:y})
        }else{
            setOldPosition({x:x,y:y})
        }
        
        
        
        // target.beginPath(); // begin
        // target.strokeStyle = color;
        // target.lineWidth = 5;
        // target.lineJoin = 'round';
        // target.lineCap = 'round';
        // target.moveTo(x,y);
        // target.lineTo(x,y);
        // target.stroke();
        // target.closePath();
        
        
           	
	}
    const handleSwitchStatus=(event)=>{
        switchDrawStatus();
    }
    const handleSwitchOf = ()=>{
        let canvas = document.getElementById('DrawIt')
        let imgUrl = canvas.toDataURL();
        setOldPosition(null)
        registerImgUrl(imgUrl)
        sendImgUrl(imgUrl)
        switchDrawOff()
    }

    let testPush = (imgData)=>{
        const postData = new URLSearchParams({
            'topic': 'http://example.com/books/1',
            'data': JSON.stringify({ imageUrl: imgData }),
        });
        fetch(mercureUrlToUse,{
            headers: {
                Authorization: `Bearer ${jwt}`,
                // the JWT must have a mercure.publish key containing an array of topic selectors (can contain "*" for all topics, and be empty for public updates)
                // the JWT key must be shared between the hub and the server
                'Content-Type': 'application/x-www-form-urlencoded',
                
            },
        method:"POST",
        body: postData

        }).then(response=>response).then(data=>{
           
        })
        
    }
    let sendImgUrl =(img) =>{
        // console.log(img)
        const postData = new URLSearchParams({
            'topic': 'http://example.com/books/1',
            'data': JSON.stringify({ imageUrl: img }),
        });
        fetch(mercureUrlToUse,{
            headers: {
                Authorization: `Bearer ${jwt}`,
                // the JWT must have a mercure.publish key containing an array of topic selectors (can contain "*" for all topics, and be empty for public updates)
                // the JWT key must be shared between the hub and the server
                'Content-Type': 'application/x-www-form-urlencoded',
                
            },
        method:"POST",
        body: postData

        }).then(response=>response).then(data=>{
           
        })
    }
    let handleChangeColor=(event)=>{
        setColor(event.target.value)
    }
    let clearCanvas = (event)=>{
        let target =  document.getElementById('DrawIt').getContext('2d');
        target.clearRect(0, 0, target.canvas.width, target.canvas.height);
        let canvas = document.getElementById('DrawIt')
        let imgUrl = canvas.toDataURL(); 
        console.log(imgUrl)
        sendClearAction()
    }
    let clearCanvasLocal = (event)=>{
        let target =  document.getElementById('DrawIt').getContext('2d');
        target.clearRect(0, 0, target.canvas.width, target.canvas.height);
    }
    let sendClearAction =()=>{
        const postData = new URLSearchParams({
            'topic': 'http://example.com/books/1',
            'data': JSON.stringify({ action: 'clear' }),
        });
        fetch(mercureUrlToUse,{
            headers: {
                Authorization: `Bearer ${jwt}`,
                // the JWT must have a mercure.publish key containing an array of topic selectors (can contain "*" for all topics, and be empty for public updates)
                // the JWT key must be shared between the hub and the server
                'Content-Type': 'application/x-www-form-urlencoded',
                
            },
        method:"POST",
        body: postData

        }).then(response=>response).then(data=>{
           
        })
        let data = qs.stringify({action:'clear'})
        axios.post(`${baseUrlToUse}/clear`,data).then((response)=>{
            
        })
    }
    let registerImgUrl =(imgUrl)=>{
        let data = qs.stringify({imageUrl:imgUrl})
        axios.post(`${baseUrlToUse}/register`,data).then((response)=>{
            
        })
    }
    let checkCurrentDrawImg =()=>{
        axios.get(`${baseUrlToUse}/currentImage`).then((response)=>{
            console.log(response)
            var target =  document.getElementById('DrawIt').getContext('2d');
            if(response.data.imageUrl!==null){
                let {width,height} = document.getElementById('DrawIt').getBoundingClientRect()
                console.log(hubData['imageUrl'])
                var img = new Image(width,height);
                img.src = response.data.imageUrl;
                img.onload = ()=>{
                    target.drawImage(img,0,0);  
                }
            }
        })
    }
    let shareCanvas =()=>{
        let canvas = document.getElementById('DrawIt')
        let imgUrl = canvas.toDataURL(); 
        registerImgUrl(imgUrl)
        sendImgUrl(imgUrl)
    }
    

    return(
        <>
            <div className="drawGame">
                <div className="canvasOption">
                    <div>
                        <input type="color" value={color} onChange={handleChangeColor}/>
                    </div>
                    <div>
                        <button className="btn btn-info" onClick={clearCanvas}>Clear</button>
                    </div>
                </div>
                <div id="gameContainer" className="d-flex ">
                    {/* <div className="chatContainer bg-info d-flex flex-column justify-content-end" >
                        <div id="bubbleContainers">hello world</div>
                        <div className="input-group">
                    <textarea className="form-control" aria-label="With textarea"></textarea>
                    </div>
                    </div> */}
                    <canvas
                    onTouchStart={handleSwitchOf}
                    onTouchMove={handleMove}
                    onTouchEnd={handleSwitchOf}
                    // onMouseMove={handleMove}
                    // onMouseDown={handleSwitchStatus}
                    // onMouseUp={handleSwitchStatus}
                    // onMouseLeave={handleSwitchOf}
                    // onMouseEnter={handleSwitchOf}
                    // onClick={handleSwitchOf}
                    id="DrawIt"
                    
                    />
                </div>
            </div>
        </>
    )
}