import {
  FIRST_TYPE, LOGIN, LOGOUT
} from '../store/reducer/appReducer';
import {
  SET_MODAL_DATA
} from '../store/reducer/modalReducer';
const Middleware = (store) => (next) => (action) => {
  
  
  next(action);
  

  switch (action.type) {
    case FIRST_TYPE: {
      
      next(action);
      break;
    }
    case LOGIN: {
      sessionStorage.setItem('token', action.data.token);
      
      next(action);
      break;
    }
    case SET_MODAL_DATA: {
     
      next(action);
      break;
    }
    case LOGOUT: {
      sessionStorage.removeItem('token');
      next(action);
      break;
    }
    default:
      //next(action);
  }
};

export default Middleware;