import { Routes,Route,useNavigate } from "react-router-dom"
import { useEffect } from 'react';
import DrawGameContainer from "./container/drawGameContainer";
import HomepageContainer from "./container/homepageContainer";
import DashboardContainer from "./container/dashboardContainer";
import DrawGamePrivateContainer from "./container/drawGamePrivateContainer";
import DrawGameKafkaContainer from "./container/drawGameKafkaContainer";
import LegalMention from "./components/legalMention";
const App = ({isConnected,loginAction}) => {
  let navigate = useNavigate();
  useEffect(()=>{  
    if(sessionStorage.getItem("token")==null){
      navigate("/");
    }else{
      loginAction({token:sessionStorage.getItem("token")});
    }
  },[])
  useEffect(()=>{  
    if(sessionStorage.getItem("token")==null){
      navigate("/");
    }else{
      loginAction({token:sessionStorage.getItem("token")});
    }
  },[isConnected])
  return (
    <>
    <div className="">
        <div className="">
            <Routes>
                <Route path="/" element={<HomepageContainer/>} />
                <Route path="/privacy" element={<LegalMention/>} />
                <Route path="/confirmation/:confirmationtoken" element={<HomepageContainer/>} />
                <Route path="/recover/:recovertoken" element={<HomepageContainer/>} />
                <Route path="/dashboard" element={<DashboardContainer/>} />
                <Route path="/drawGame" element={<DrawGameKafkaContainer/>} />
                <Route path="/drawGamePrivate" element={<DrawGamePrivateContainer/>} />
            </Routes>
        </div>
    </div>
    </>
  )
}

export default App;
