import { connect } from 'react-redux';

// == Import : local
import ModalShow from '../components/modal';
// Action Creators
import { baseUrlToUse } from '../store/reducer/appReducer';
import { setModalData } from '../store/reducer/modalReducer';
/* === State (données) ===
 * - mapStateToProps retroune un objet de props pour le composant de présentation
 * - mapStateToProps met à dispo 2 params
 *  - state : le state du store (getState)
 *  - ownProps : les props passées au container
 * Pas de data à transmettre ? const mapStateToProps = null;
 */
const mapStateToProps = (state) => ({ 
    isConnected: state.appReducer.isConnected,
    baseUrlToUse:baseUrlToUse,
    title:state.modalReducer.title,
    content:state.modalReducer.content,
    showState:state.modalReducer.showState,
});

/* === Actions ===
 * - mapDispatchToProps retroune un objet de props pour le composant de présentation
 * - mapDispatchToProps met à dispo 2 params
 *  - dispatch : la fonction du store pour dispatcher une action
 *  - ownProps : les props passées au container
 * Pas de disptach à transmettre ? const mapDispatchToProps = {};
 */
const mapDispatchToProps = (dispatch) => ({
  setModalData: (data) => {
    dispatch(setModalData(data));
  },
});

// Container
const ModalShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalShow);

// == Export
export default ModalShowContainer;

/* = export à la volée
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Example);
*/