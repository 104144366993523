import './dashboard.scss'
import { useNavigate } from "react-router-dom"
import ModalShowContainer from '../../container/modalContainer'
let Dashboard = ({setModalData}) => {
    let navigate = useNavigate();
    return(
        <>
         <ModalShowContainer  />
        <div className="dashboardContainer">
            <div className='dashboardCardContainers'>
                <div>
                    <div onClick={()=>navigate('/drawGame')} className="dashboardCard">
                    <span className="dashboardItemTitle mb-2">World draw</span>
                    <span className="dashboardItemIcon"><i className="fa-solid fa-globe iconSize"></i></span>
                    </div>
                    <div onClick={()=>navigate('/drawGamePrivate')} className="dashboardCard">
                        <span className="dashboardItemTitle  mb-2">Private gallery</span>
                        <span className="dashboardItemIcon"><i className="fas fa-photo-video iconSize"></i></span>
                    </div>
                </div>
                <div>
                    <div onClick={()=>setModalData({showState:true,title:'Feature',content:'This feature is still on developement'})} className="dashboardCard">
                        <span className="dashboardItemTitle mb-2">Scrib game</span>
                        <span className="dashboardItemIcon"><i className="fas fa-palette iconSize"></i></span>
                    </div>
                    <div onClick={()=>setModalData({showState:true,title:'Feature',content:'This feature is still on developement'})} className="dashboardCard">
                        <span className="dashboardItemTitle mb-2">Feed our AI</span>
                        <span className="dashboardItemIcon"><i className="fas fa-brain iconSize"></i></span>
                    </div>
                </div>
                
            </div>
        </div>
        </>
    )
}

export default Dashboard;